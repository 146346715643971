
import { Col, Row } from "react-bootstrap";
import {FlatList, StyleSheet, Text, View} from 'react-native';

export const Newsletter = () => {

  return (
      <Col lg={12}>
        <div className="newsletter-bx wow slideInUp">
          <Row>
            <Col lg={12} md={6} xl={5}>
              <h3>Si has llegado hasta aquí, porque no saber que también ayudo en:</h3>
            </Col>
            <Col md={6} xl={7}>
<View style={styles.container}>
      <FlatList
        data={[
          {key: '* Gestión de Redes Inalámbricas Unify'},
          {key: '* Wordpress,WooCommerce;SEO avanzado y SEMrush'},
          {key: '* Photoshop e Illustrator'},
          {key: '* PowerBI.DAX,Diseño de informes y preparación de datos'},
        ]}
        renderItem={({item}) => <Text style={styles.item}>{item.key}</Text>}
      />
    </View>
            </Col>
          </Row>
        </div>
      </Col>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 22,
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
});

