import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
      <picture>
                    <source type="image/webp" srcSet={imgUrl} alt="Imagen_{imgUrl}"/>
                    <source type="image/jpeg" srcSet={imgUrl} alt="Imagen_{imgUrl}"/>
                    <img srcSet={imgUrl} alt="Imagen_{imgUrl}"/>
                    </picture>
        {/* <img src={imgUrl} alt="Imagen_{imgUrl}" /> */}
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
    </Col>
  )
}
