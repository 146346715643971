import { useState } from "react";
import { useRef } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Newsletter } from "./Newsletter";
import emailjs from '@emailjs/browser';
export const Contact = () => {
  const form = useRef();
  const [formDetails, setFormDetails] = useState(form);
  const [buttonText, setButtonText] = useState('Enviar');
  const [status, setStatus] = useState({});
  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
}
  const sendEmail = (e) => {
    e.preventDefault();
    setButtonText("Enviando...");
    emailjs.sendForm('service_vwwdj2h', 'template_zqca5hr', form.current, 'lunfN2EfcX-GoPBna')
         .then((result) => {
        setStatus({ succes: true, message: 'Consulta enviada correctamente'});
        setButtonText("Enviado");
      }, (error) => {
        setStatus({ succes: false, message: 'Error al enviar la consulta.Inténtelo nuevamente'});
        setButtonText("Error");
      });
  };


  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
        <Newsletter />
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>¿ Hablamos ?</h2>
                <form ref={form} onSubmit={sendEmail}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="firstName" value={formDetails.firstName}  placeholder="Nombre" onChange={(e) => onFormUpdate('firstName', e.target.value)}  />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="lastName" value={formDetails.lasttName} placeholder="Apellidos" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" name="email" value={formDetails.email}  placeholder="Email " onChange={(e) => onFormUpdate('email', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" name="tel" value={formDetails.phone} placeholder="Teléfono" onChange={(e) => onFormUpdate('phone', e.target.value)} />
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" name="message" value={formDetails.message}  placeholder="Consulta" onChange={(e) => onFormUpdate('message', e.target.value)} ></textarea>
                      <button type="submit"><span>{buttonText}</span></button>
                    </Col>
                    {
                      status.message &&
                      <Col>
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </Col>
                    }
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
